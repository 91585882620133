<script lang="ts" setup>
  import RouletteSlider from '~/components/sliders/roulette/RouletteSlider.vue';
  import SkeletonGenres from '~/pages/browse/components/SkeletonGenres.vue';
  import GenreCard from '~/components/cards/GenreCard.vue';
  import { getGenres } from '~/services/modules/movies.service';

  const { data, suspense, isFetching } = getGenres();

  onServerPrefetch(async () => await suspense());
</script>

<template>
  <v-wrapper section class="section-genres full-width" removePadding title="Жанры">
    <roulette-slider
      appendClass="continue__slider content-right control-normal"
      :items="data ?? []"
      :is-fetching="isFetching"
      skeleton
    >
      <template #default="{ movie }">
        <genre-card :data="movie" />
      </template>
      <template #skeleton>
        <skeleton-genres />
      </template>
    </roulette-slider>
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .section-genres {
    position: relative;
    margin-bottom: 76px;
    &.roulette-slider__control {
      height: 100%;
    }
    @include gradientBackground(-400px);
    @media (max-width: $retina) {
      margin-bottom: 60px;
      //padding: 24px 0;
    }
  }
</style>
